.bg-gradient-primary {
    background-color: #5b2be0;
    background-image: linear-gradient(130deg, #4922b3 15%, #5b2be0 40%, #5b2be0 60%, #7c55e6 100%) !important;
}
.animated-up-down {
    animation: up-down 1.5s ease-in-out infinite alternate-reverse both;
    -webkit-animation: up-down 1.5s ease-in-out infinite alternate-reverse both; 
}
@keyframes up-down {
    0% { 
        transform: translateY(0); 
        -webkit-transform: translateY(0); 
    }
    100% { 
        transform: translateY(-10px); 
        -webkit-transform: translateY(-10px); 
    }
}

.hover-button-up {
    transition: all 0.1s ease-in-out;
}
.hover-button-up:hover{
    box-shadow: 0 0.2rem 1.25rem 0 rgba(77, 91, 237, 0.1019607843);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
}
.btn-white, .btn-white:hover{
    background: #fff;
    border-color: #fff;
    color: #000;
}


.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    border-radius: inherit;
}
.opacity-90 {
    opacity: 0.9 !important;
}
.z-index-n1 {
    z-index: -1;
}
