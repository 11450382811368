.sidebar-list {
    list-style-type: none;
  }
  
  .sidebar-list li {
    padding: 10px;
  }
  
  .nav-item{
    text-decoration: none;
    color: black;
  }
  
  .nav-link:hover {
    color: blue;
  }
  
  .nav-item p {
    margin: 0;
    padding: 0;
  }
  .sidebar-list {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar-list .nav-item {
    margin-bottom: 10px;
  }
  
  .sidebar-list .nav-item a {
    display: flex;
    align-items: center;
    text-decoration: none;
    line-height: 35px;
    color: black;
    will-change: transform;
    transition: transform 450ms;
    margin-left: -10px;
  }
  
  .sidebar-list .nav-item a p {
    margin-left: 10px;
  }
  
  .sidebar-list .nav-item a:hover {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    line-height: 40px;
    transition: transform 125ms;
    transform: translateY(-10px);
  }
  
  .sidebar-list .nav-item.active a {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: px;
    border-left: 1px solid #007bff; 
    margin-left: -10px;
  }
  
  .sidebar-list .nav-item.active a:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  

  
  /* Sidebar.css */
.sidebar {
  transition: all 0.3s ease;
}

.burger-menu {
  display: none;
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.sidebar-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-list.open {
  display: block;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
  }
  
  .burger-menu {
    display: block;
  }
  
  .sidebar-list {
    display: none;
    width: 100%;
    background: #fff;
    position: absolute;
    top: 50px; /* Adjust based on your design */
    left: 0;
    right: 0;
    z-index: 1000;
  }
  
  .sidebar-list.open {
    display: block;
  }
  
  .nav-item {
    padding: 10px 20px;
  }
  
  .nav-item a {
    color: #000;
    text-decoration: none;
  }
}
