.position-absolute.end-0 {
    right: 1rem;
  }
  
  .mt-5 {
    margin-top: 3rem !important;
  }
  
  @media (max-width: 768px) {
    .mt-5 {
      margin-top: 2rem !important;
    }
  }